@import "../../theme/anim.scss";

.simpleMahouLoaderBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.25);

  .simpleMahouLoaderWithBackground {
    width: 50%;
    height: 80%;
    max-width: 6rem;
    left: max(25%, calc(50% - 3rem));
    top: 10%;
    animation: 1.5s linear 0s infinite alternate fadeAnim;
    filter: opacity(0.5);
  }
}

.simpleMahouLoader {
  position: absolute;
  width: 50%;
  height: 80%;
  max-width: 6rem;
  left: max(25%, calc(50% - 3rem));
  top: 10%;
  animation: 1.5s linear 0s infinite alternate fadeAnim;
  filter: opacity(0.5);
}
