#ProposalExternal {
  ion-content {
    .text {
      display: flex;
      height: 100%;
      width: 80%;
      align-items: center;
      justify-content: center;
      text-align: center;
      justify-self: center;
      font-size: 20px;
    }
  }
}
