@import "@france/superelements/theme/size.scss";

$sm: 576px;
$md: 768px;

body {
  --sm: #{$sm};
  --md: #{$md};
}

$headerSize: 5rem;
$footerSize: 5rem;
