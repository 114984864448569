@font-face {
  font-family: "Real Head Pro";

  src: url("./fonts/100-thin/font.eot");
  src:
    url("./fonts/100-thin/font.woff") format("woff"),
    url("./fonts/100-thin/font.woff2") format("woff2"),
    url("./fonts/100-thin/font.ttf") format("truetype"),
    url("./fonts/100-thin/font.svg") format("svg");

  font-weight: 100;
}

@font-face {
  font-family: "Real Head Pro";

  src: url("./fonts/350-light/font.eot");
  src:
    url("./fonts/350-light/font.woff") format("woff"),
    url("./fonts/350-light/font.woff2") format("woff2"),
    url("./fonts/350-light/font.ttf") format("truetype"),
    url("./fonts/350-light/font.svg") format("svg");

  font-weight: 350;
}

@font-face {
  font-family: "Real Head Pro";

  src: url("./fonts/375-semilight/font.eot");
  src:
    url("./fonts/375-semilight/font.woff") format("woff"),
    url("./fonts/375-semilight/font.woff2") format("woff2"),
    url("./fonts/375-semilight/font.ttf") format("truetype"),
    url("./fonts/375-semilight/font.svg") format("svg");

  font-weight: 375;
}

@font-face {
  font-family: "Real Head Pro";

  src: url("./fonts/500-medium/font.eot");
  src:
    url("./fonts/500-medium/font.woff") format("woff"),
    url("./fonts/500-medium/font.woff2") format("woff2"),
    url("./fonts/500-medium/font.ttf") format("truetype"),
    url("./fonts/500-medium/font.svg") format("svg");

  font-weight: 500;
}

@font-face {
  font-family: "Real Head Pro";

  src: url("./fonts/600-demibold/font.eot");
  src:
    url("./fonts/600-demibold/font.woff") format("woff"),
    url("./fonts/600-demibold/font.woff2") format("woff2"),
    url("./fonts/600-demibold/font.ttf") format("truetype"),
    url("./fonts/600-demibold/font.svg") format("svg");

  font-weight: 600;
}

@font-face {
  font-family: "Lane E";

  src: url("./fonts/lane-e/font.eot");
  src:
    url("./fonts/lane-e/font.woff2") format("woff2"),
    url("./fonts/lane-e/font.ttf") format("truetype");

  font-weight: 500;
}

* {
  font-family: "Real Head Pro", var(--ion-font-family) !important;
  font-weight: 375;
}
