.profilePicture {
  position: relative;
  > .bouncer {
    position: absolute;
    top: 0;
    left: 0;

    pointer-events: none;
    transform-origin: center;
    background-color: var(--ion-color-grey-dark);
    border-radius: 100%;
    z-index: -1;

    // ease in-out sine
    animation: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite normal
      profilePictureBouncer;
  }

  > a > .UserPicture {
    background-color: var(--ion-color-white);
  }
}

@keyframes profilePictureBouncer {
  from {
    opacity: 0.6;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.2);
  }
}
