/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-text-color: #2c2c2c;
  --ion-color-gold: #ae822b;
  --ion-color-green-dark: #13342d;

  /** primary **/
  --ion-color-primary: #c8102e; // Mahou Red
  --ion-color-primary-rgb: 200, 16, 46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #960c23; // Mahou Red dark
  --ion-color-primary-shade-rgb: 150, 13, 36;
  --ion-color-primary-tint: #d04f65; // Can be changed
  --ion-color-primary-tint-rgb: 208, 79, 101;

  /** secondary **/
  --ion-color-secondary: #b2a384; // Ocre Mahou
  --ion-color-secondary-rgb: 178, 163, 132;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #7f745e; // Can be changed
  --ion-color-secondary-shade-rgb: 127, 116, 94;
  --ion-color-secondary-tint: #d1ccbd;
  --ion-color-secondary-tint-rgb: 209, 204, 189; // Ocre Mahou Claro

  /** tertiary **/
  --ion-color-tertiary: #4b3d2a; // Tapacio Mahou
  --ion-color-tertiary-rgb: 75, 61, 42;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #7f745e; // Can be changed
  --ion-color-secondary-shade-rgb: 127, 116, 94;
  --ion-color-secondary-tint: #d1ccbd;
  --ion-color-secondary-tint-rgb: 209, 204, 189; // Can be changed

  /** success **/
  --ion-color-success: #dcffd7;
  --ion-color-success-rgb: 220, 255, 215;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-success-shade-dark: #59ac5f;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** shade **/
  --ion-color-shade: #222428;
  --ion-color-shade-rgb: 34, 36, 40;
  --ion-color-shade-contrast: #ffffff;
  --ion-color-shade-contrast-rgb: 255, 255, 255;
  --ion-color-shade-shade: #1e2023;
  --ion-color-shade-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** tint **/
  --ion-color-tint: #f4f5f8;
  --ion-color-tint-rgb: 244, 245, 248;
  --ion-color-tint-contrast: #000000;
  --ion-color-tint-contrast-rgb: 0, 0, 0;
  --ion-color-tint-shade: #d7d8da;
  --ion-color-tint-tint: #f5f6f9;
}
