@import "../../../theme/size.scss";

$width: 5rem;

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: $width;
  max-width: $width;
  overflow: visible;

  > menu {
    padding: 1.5rem;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: var(--sp-ml);

    > button[role="menuitem"] {
      position: relative;
      display: flex;
      align-items: center;
      background-color: transparent;
      list-style: none;
      box-sizing: border-box;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.844rem; // 13.5px
      text-decoration: none;
      color: var(--ion-color-black);

      > span {
        flex-direction: row;
        align-items: center;
        justify-items: start;
        position: absolute;
        top: 0;
        left: 2rem;
        bottom: 0;
        padding: 0.75rem;
        background-color: #fff;
        box-shadow: var(--ion-box-shadow);
        border-radius: 0.5rem;
        display: none;
        font-size: 1rem; // 1rem
        font-weight: 500;
        z-index: 100;
      }

      &:hover {
        span {
          display: flex;
        }
      }

      &.selected {
        color: var(--ion-color-primary);
      }
    }

    .ButtonHighlight {
      padding: 0;
      width: 2.75rem;
      height: 2.75rem;

      .icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
