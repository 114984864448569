@import "../../../theme/size.scss";

#AuthDevProvider {
  --height: 100vh;
  --width: 100vw;
  background-image: url("https://ok9static.oktacdn.com/fs/bco/7/fs080lkzujx500Oob417");
  background-position: center;
  background-size: cover;

  .container {
    height: 100vh;
    width: 100vw;
    position: relative;
    background-image: url("https://ok9static.oktacdn.com/fs/bco/7/fs080lkzujx500Oob417");
    background-position: center;
    background-size: cover;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .langButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      height: $headerSize;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .box {
      background-color: #fff;
      width: 400px;
      height: auto;
      border-radius: 0.5rem;
      border: 1px solid var(--ion-color-grey-medium);

      @media (max-width: $md) {
        width: 80vw;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 90px;
        border-bottom: 1px solid var(--ion-color-grey-medium);

        img {
          height: 3rem;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1.5rem 3.5rem;

        @media (max-width: $sm) {
          padding: 1.5rem 2rem;
        }

        > .label {
          font-size: 1rem;
          padding-bottom: 1rem;

          &.error {
            font-size: 0.9rem;
            padding-bottom: 0.25rem;
            color: var(--ion-color-danger);
          }
        }

        .inputBox {
          position: relative;
          width: 100%;
          .label {
            padding-bottom: 0.5rem;
          }

          .eye {
            position: absolute;
            right: 0.5rem;
            bottom: 0.5rem;
          }
        }
        input {
          padding: 0.5rem;
          width: 100%;
          border-radius: 0.25rem;
          border: 1px solid black;

          &.error {
            border: 1px solid var(--ion-color-danger);
          }

          &.hasEye {
            padding-right: 2.5rem;
          }
        }

        .ButtonHighlight {
          margin-top: 2rem;
          width: 100%;
        }

        .link {
          cursor: pointer;
        }
      }
    }
  }
}
