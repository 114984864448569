.ProfileContent {
  .mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .profileContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    &.col {
      flex-direction: column;
      .informations > h1 {
        padding-top: var(--sp-s);
        padding-bottom: var(--sp-ml);
      }
    }
    &.row {
      flex-direction: row;
      .informations > h1 {
        padding-bottom: var(--sp-ms);
        margin-right: var(--sp-l);
      }
    }

    .informations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .details {
        display: flex;
        flex-direction: column;

        .profileDetail {
          display: flex;
          gap: 13px;
          align-items: center;
          margin-bottom: var(--sp-ms);
        }
      }
    }
  }
}
