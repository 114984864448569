@import "../../theme/size.scss";

#ProposalDraftModal {
  --width: 500px;
  --height: auto;
  --border-radius: 0.5rem;

  @media (max-width: $sm) {
    --width: calc(100vw - 3rem);
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem;

    .head {
      font-weight: 400;
      font-size: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--ion-color-grey-light);
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0rem;

      .box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid var(--ion-color-grey-medium);

        .title,
        .desc {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;
        }

        .desc {
          .recapStep {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #000;
            border-radius: 50%;
            min-width: 2rem;
            max-width: 2rem;
            min-height: 2rem;
            max-height: 2rem;
            color: #fff;
            font-weight: 400;
          }
          .text {
            flex: 1;
          }
        }
      }
      .warn {
        display: flex;
        flex-direction: row;
        padding: 1rem;
        gap: 0.5rem;
        align-items: center;
        border-radius: 0.5rem;
        background-color: rgba(var(--ion-color-danger-rgb), 0.25);

        .icon {
          height: 2rem;
          width: 2rem;
          margin-right: 0.5rem;
        }
      }
    }
    .footer {
      padding-top: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .ButtonHighlight {
        flex: 1;
        background-color: #000;

        &:first-child {
          color: #000;
          background-color: #fff;
          border: 1px solid #000;
        }
      }
    }
  }
}
