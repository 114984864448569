@keyframes scaleAnim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
@keyframes fadeAnim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}
