@import "../../../theme/size.scss";

#MainFooter {
  height: $footerSize;
  box-shadow: var(--ion-box-shadow);
  border-top-left-radius: var(--sp-ms);
  border-top-right-radius: var(--sp-ms);
  background-color: var(--ion-color-white);
  overflow: hidden;

  > nav {
    width: 100%;
    height: 100%;

    > ul {
      display: flex;
      flex-flow: row nowrap;
      padding: 0;
      margin: 0;
      height: $footerSize;

      > li {
        cursor: not-allowed;
        opacity: 50%;
      }

      > a {
        &:hover > svg {
          stroke-width: 2;
        }

        &.currentPage {
          > svg {
            stroke-width: 2;
          }
          color: var(--ion-color-primary);
        }
      }

      > a,
      > li {
        flex: 0 0 20%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: 0.1rem;
        color: var(--ion-color-black);

        text-decoration: none;

        > svg {
          width: 1.75rem;
          height: 1.75rem;
        }

        > span {
          flex: 0 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          font-size: 0.8rem;
          line-height: 10px;
          text-align: center;
        }
      }
    }
  }
}
