/* width */
::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #efefef;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 0.5rem;
}

.hiddenScrollBar {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  /* width */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    display: none;
  }
}
