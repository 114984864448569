@import "../../theme/size.scss";
@import "@france/superelements/theme/global.scss";

#MainHeader {
  height: $headerSize;
  overflow: visible;

  .headerToolbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    padding: 0 var(--sp-ms);
    gap: 0.8rem;

    .spacer {
      display: flex;
      flex: 1;
    }

    > a {
      display: flex;
      align-items: center;

      > .animatedLogo {
        cursor: pointer;
        width: auto;
        object-position: left;
        user-select: none;
      }
    }

    > .ButtonHighlight {
      background-color: transparent;
      color: var(--ion-color-primary);
    }

    > p {
      padding-right: 0.3rem;
    }

    .UserPicture {
      width: 3.5rem;
      height: 3.5rem;
      cursor: pointer;
    }
  }

  .langButton {
    .ActionButton {
      width: 126px;
    }
    @media (max-width: $sm) {
      display: none;
    }
  }
}

@media (max-width: $sm) {
  #MainHeader {
    overflow: hidden;
  }
}

@media (min-width: $md) {
  #MainHeader {
    padding: 0 var(--sp-md);
    margin-bottom: unset;
    .headerToolbar {
      padding: unset;

      .deskTopUserPicture {
        display: inline;
      }
    }
  }
}
