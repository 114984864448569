@import "../../theme/size.scss";

.myCurrentPage {
  background-color: var(--ion-background-color);
  .page {
    --padding-top: 2.5rem;
    --padding-bottom: 2.5rem;
    --padding-start: 1.5rem;
    --padding-end: 1.5rem;
  }
}

#MainPageLoader {
  --background: transparent;
  --spinner-color: var(--ion-color-primary);
  ion-spinner {
    width: var(--sp-l) !important;
    height: var(--sp-l) !important;
  }
}

#MainPage {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  border: none;

  > #MainHeader {
    box-sizing: border-box;
    transition: all 0.5s;

    flex: 0 0 0px;
  }

  > .content {
    flex: 1;
    display: flex;
    flex-flow: row-reverse nowrap;
    overflow: hidden;

    > #CustomOutlet,
    > .wrappedLoader {
      flex: 1;
      position: relative;
      display: block;

      > .ion-page > ion-content {
        --background: transparent;
      }

      > .ion-page {
        height: 100%;
        background-color: var(--ion-background-color);

        &.noHeader {
          padding-top: 0;
        }
      }
    }

    > .side-navigation-menu {
      box-sizing: border-box;
      transition: all 0.5s;
      width: 0;
      max-width: 0;
      z-index: -1;
      opacity: 0;
    }
  }

  > #MainFooter {
    transition:
      transform 0.5s,
      height 0.5s;

    position: fixed;
    left: 0;
    bottom: calc(0px - constant(safe-area-inset-bottom));
    bottom: calc(0px - env(safe-area-inset-bottom));
    width: 100%;
    min-height: calc(4.375rem + constant(safe-area-inset-bottom));
    min-height: calc(4.375rem + env(safe-area-inset-bottom));
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
  }

  > .firstLoader {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: $md) {
  #MainPage {
    > #MainHeader {
      border-bottom: 1px solid var(--ion-color-grey-medium-light);
      flex: 0 0 $headerSize;
    }

    > .content {
      > .side-navigation-menu {
        z-index: 1;
        width: unset;
        max-width: unset;
        opacity: 1;
        border-right: 1px solid var(--ion-color-grey-medium-light);
      }

      > #CustomOutlet {
        > .ion-page {
          padding-bottom: constant(safe-area-inset-bottom);
          padding-bottom: env(safe-area-inset-bottom);
        }
      }
    }

    > #MainFooter {
      height: 0px;
      transform: translateY(100%);
      display: none;
    }
  }
}
